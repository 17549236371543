import { useDispatch, useSelector } from 'react-redux';
import {
  transform,
  validationPreviousCreation,
} from '../../../forms/invoice/utils';
import {
  country as countrySelector,
  isOnlyInvoicingPlan,
} from '../../../../selectors/company';
import { client as clientSelector } from '../../../../selectors/activeInvoice';
import {
  numeration as invoiceNumerationSelector,
  items as itemsSelector,
} from '../../../../selectors/activeInvoice';
import {
  stationInvoiceNumeration,
  station as stationSelector,
} from '../../../../selectors/app';
import { checkStationValues } from '../../../../reducers/app';
import {
  clear,
  payInvoice,
  setSettings,
} from '../../../../reducers/activeInvoice';
import { closeModal, openModal } from '../../../../reducers/modals';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { economicActivitiesSelector } from '../../../../selectors/auth';
import { formError } from '../../../../utils/errors';
import { I18n } from 'aws-amplify';
import { get } from 'lodash';
import { useState } from 'react';
import { toast } from '../../../../utils';
import { payInvoice as payInvoiceRefactored } from '../../../../reducers/refactoredActiveInvoice';
import { isCountryAvailableToPayInvoiceRefactored } from '../utils/invoice';
import { allPaymentMethods } from '../../../../selectors/paymentMethods';
import { showNewInvoiceModal } from '../../newInvoice/utils';
import { useModal } from '@alegradev/smile-ui-react';
import useNotification from '../../../../hooks/useNotification/hook';
import { NEW_INVOICE_MODAL_USE } from '../../../forms/newInvoice/utils';
import NewInvoiceSaved from '../../newInvoiceSaved/NewInvoiceSaved';

const UseSubmitInvoice = (props = {}) => {
  const { isNewModal = false } = props;
  const [loading, setLoading] = useState(false);
  const country = useSelector(countrySelector);
  const client = useSelector(clientSelector);
  const invoiceNumeration = useSelector(invoiceNumerationSelector);
  const mainNumeration = useSelector(stationInvoiceNumeration);
  const numeration = !!invoiceNumeration ? invoiceNumeration : mainNumeration;
  const items = useSelector(itemsSelector);
  const economicActivities = useSelector(economicActivitiesSelector);
  const station = useSelector(stationSelector);
  const pendingInvoicesEnabled = get(station, 'pendingInvoicesEnabled', false);
  const onlyInvoicingPlan = useSelector(isOnlyInvoicingPlan);
  const aditionalSettings = useSelector(
    (state) => state.aditionalSettings.settings
  );
  const paymentMethods = useSelector(allPaymentMethods);
  const { openModal: openNewModal, closeModal: closeNewModal } = useModal();
  const dispatch = useDispatch();
  const { viewed, setViewed } = useNotification({
    notificationName: NEW_INVOICE_MODAL_USE,
  });

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getIdPaymentMethod = (values) => {
    const method = get(values, 'paymentMethodSelected', null);
    const paymentMethod = paymentMethods.find((pm) => pm.name === method);
    return paymentMethod ? paymentMethod.idLocal : null;
  };

  const submit = async (values) => {
    const validation = validationPreviousCreation({
      ...values,
      country,
      client,
      numeration,
      items,
    });
    if (validation.hasError) {
      validation.errors.forEach((err) => {
        toast[err.type]({ ...err });
      });
      await sleep(100);
      return;
    }

    try {
      setLoading(true);
      const canContinue = await dispatch(
        checkStationValues({ type: 'invoice', values })
      );
      if (!canContinue) return;

      let objectToTransform = {
        values,
        country,
      };

      if (isNewModal) {
        objectToTransform = {
          ...objectToTransform,
          idPaymentMethodLocal: getIdPaymentMethod(values),
        };
      }
      const transformedValues = transform(
        objectToTransform?.values,
        objectToTransform?.country,
        objectToTransform?.idPaymentMethodLocal
      );

      await dispatch(
        isCountryAvailableToPayInvoiceRefactored({ country })
          ? payInvoiceRefactored(transformedValues)
          : payInvoice(transformedValues)
      );

      if (isNewModal) {
        closeNewModal('NewInvoice');
      } else {
        dispatch(closeModal({ modal: 'invoiceRefactored' }));
      }

      if (!(!!pendingInvoicesEnabled && !onlyInvoicingPlan)) {
        dispatch(clear());
        if (
          country === COUNTRIES.COSTA_RICA &&
          !!aditionalSettings['economicActivity']
        ) {
          const economicActivity = economicActivities.find(
            (activity) => activity.main === true
          );
          dispatch(setSettings({ economicActivity }));
        }
      }
      if (isNewModal) {
        showNewInvoiceModal(country)
          ? openNewModal({
              name: 'NewInvoiceSaved',
              component: NewInvoiceSaved,
              size: 'large',
            })
          : dispatch(openModal({ modal: 'newInvoiceSaved' }));
      } else {
        dispatch(openModal({ modal: 'invoiceSaved' }));
      }
    } catch (error) {
      setLoading(false);
      return formError(
        error,
        I18n.get(
          'createInvoiceError',
          'hubo un error en la creación de la factura'
        ),
        country
      );
    } finally {
      if (!viewed && isNewModal) {
        setViewed();
      }
    }
    setLoading(false);
  };

  return { submit, loading };
};

export default UseSubmitInvoice;
