import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { useDispatch, useSelector } from 'react-redux';
import { allPaymentMethods } from '../../../../selectors/paymentMethods';
import { useEffect, useState } from 'react';
import { get, isEqual } from 'lodash';
import {
  Avatar,
  Divider,
  Icon,
  Tooltip,
  Typography,
  useDeviceType,
  useModal,
} from '@alegradev/smile-ui-react';
import {
  getPaymentMethodIcon,
  getPaymentMethodName,
} from '../../../settings/PaymentMethods/utils';
import { I18n } from 'aws-amplify';
import { refresh } from '../../../../reducers/paymentMethods';
import {
  setIsPaymentMethodSelected,
  setPaymentMethod,
} from '../../../../reducers/activeInvoice';
import {
  currency as currencySelector,
  numeration as numerationSelector,
  itemsCount as itemsCountSelector,
  paymentMethod,
} from '../../../../selectors/activeInvoice';
import {
  currency as companyCurrencySelector,
  idCompanySelector,
  country as countrySelector,
  externalProviderState,
} from '../../../../selectors/company';
import { sendGTMEvent, sendNewGTMEvent } from '../../../../reducers/company';
import BigNumber from 'bignumber.js';
import { useFormat } from '../../../../hooks/useFormat';
import { replaceAndParse } from '../../../../utils';
import Nequi from '../../../svg/paymentMethods/Nequi';
import Wompi from '../../../../assets/images/wompi.png';
import Bancolombia from '../../../../assets/images/bancolombia-logo.png';
import {
  IconChevronLeft,
  IconChevronRight,
  IconPointFilled,
  IconSettings,
} from '@tabler/icons-react';
import { useForm } from 'react-final-form';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const NewModalMethods = ({ total, paymentsValues, setPaymentsValues }) => {
  const paymentsMethods = useSelector(allPaymentMethods);
  const paymentMethodSelected = useSelector(paymentMethod);
  const numeration = useSelector(numerationSelector);
  const currency = useSelector(currencySelector);
  const companyCurrency = useSelector(companyCurrencySelector);
  const itemsCount = useSelector(itemsCountSelector);
  const nequiState = useSelector(externalProviderState('nequi'));
  const wompiState = useSelector(externalProviderState('wompi'));
  const deviceType = useDeviceType();

  const { updateModal } = useModal();

  const [mainPaymentMethodList, setMainPaymentMethodList] = useState([]);
  const [otherPaymentMethodList, setOtherPaymentMethodList] = useState([]);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const dispatch = useDispatch();

  const WOMPI_MIN_TOTAL = new BigNumber(1500);

  const { fmt, decimal } = useFormat();

  const form = useForm();

  useEffect(() => {
    if (paymentMethodSelected) {
      if (
        paymentMethodSelected !== 'cash' &&
        paymentMethodSelected !== 'debit' &&
        paymentMethodSelected !== 'credit' &&
        paymentMethodSelected !== 'transfer' &&
        paymentMethodSelected !== 'combined'
      ) {
        form.change('transfer', total.toString());
      } else if (
        paymentMethodSelected !== 'cash' &&
        paymentMethodSelected !== 'combined'
      ) {
        setPaymentsValues({
          ...paymentsValues,
          [paymentMethodSelected]: total,
        });
      }
    }
  }, [form, paymentMethodSelected, total]);

  useEffect(() => {
    const mainList = [];
    const otherList = [];
    if (paymentsMethods.length > 0) {
      mainList.push(paymentsMethods.find((method) => method.name === 'cash'));
      mainList.push(paymentsMethods.find((method) => method.name === 'credit'));
      mainList.push(
        paymentsMethods.find((method) => method.name === 'transfer')
      );
    }
    paymentsMethods.forEach((method) => {
      if (
        get(method, 'name') === 'cash' ||
        get(method, 'name') === 'credit' ||
        get(method, 'name') === 'transfer'
      ) {
        //mainList.push(method);
      } else if (get(method, 'status') === 'active') {
        otherList.push(method);
      }
    });

    setMainPaymentMethodList(mainList);
    setOtherPaymentMethodList(otherList);
  }, [paymentsMethods]);
  const handleOpenOtherMethods = () => {
    window.open('/settings/payment-methods', '_blank');
  };

  const sendPaymentMethodSelectedEvent = (paymentMethod) => {
    dispatch(
      sendNewGTMEvent('pos-sale-integration-method-selected', {
        type: paymentMethod,
        amount: parseFloat(total),
      })
    );
  };

  return (
    <div
      className={`payment-methods-container${deviceType === 'mobile' ? '-mobile' : ''}`}
    >
      {deviceType === 'mobile' ? (
        <div className='mobile'>
          <div className='d-flex justify-content-center'>
            <Typography
              type='label-2'
              text={I18n.get('invoiceMethod', 'Metodo de pago')}
              variant='secondary'
            />
          </div>
          {mainPaymentMethodList.map((method) => {
            return (
              method.status === 'active' && (
                <button
                  key={get(method, 'id')}
                  type='button'
                  className='btn-invoice-method-mobile'
                  data-testid={`payment-method-${get(method, 'name')}`}
                  onClick={() => {
                    dispatch(setPaymentMethod(get(method, 'name')));
                    sendPaymentMethodSelectedEvent(paymentMethodSelected);
                    form.change('method', paymentMethodSelected);
                    dispatch(setIsPaymentMethodSelected(true));
                  }}
                >
                  <div
                    className={`btn-invoice-method-border-mobile ${paymentMethodSelected === get(method, 'name') ? 'selected' : ''}`}
                  >
                    <div className='btn-invoice-method-icon-mobile'>
                      {get(method, 'logo.url') ? (
                        <img src={get(method, 'logo.url')} width={40} />
                      ) : (
                        <Icon
                          size='extraLarge'
                          icon={getPaymentMethodIcon(get(method, 'name'))}
                          color='#64748B'
                        />
                      )}
                    </div>
                    <div className='btn-invoice-method-text'>
                      <Typography
                        text={getPaymentMethodName(get(method, 'name'))}
                        type='body-3-bold'
                        variant='tertiary'
                      />
                    </div>
                  </div>
                </button>
              )
            );
          })}
          <div className='d-flex justify-content-center'>
            <Typography
              type='label-2'
              text={I18n.get('otherMethods', 'Otros métodos')}
              variant='secondary'
            />
          </div>
          {otherPaymentMethodList.map((method) => {
            return (
              method.status === 'active' && (
                <button
                  key={get(method, 'id')}
                  type='button'
                  className='btn-invoice-method-mobile'
                  data-testid={`payment-method-${get(method, 'name')}`}
                  onClick={() => {
                    dispatch(setPaymentMethod(get(method, 'name')));
                    sendPaymentMethodSelectedEvent(paymentMethodSelected);
                    form.change('method', paymentMethodSelected);
                    dispatch(setIsPaymentMethodSelected(true));
                  }}
                >
                  <div
                    className={`btn-invoice-method-border-mobile ${paymentMethodSelected === get(method, 'name') ? 'selected' : ''}`}
                  >
                    <div className='btn-invoice-method-icon-mobile'>
                      {get(method, 'logo.url') ? (
                        <img src={get(method, 'logo.url')} width={40} />
                      ) : (
                        <Icon
                          size='extraLarge'
                          icon={getPaymentMethodIcon(get(method, 'name'))}
                          color='#64748B'
                        />
                      )}
                    </div>
                    <div className='btn-invoice-method-text'>
                      <Typography
                        text={getPaymentMethodName(get(method, 'name'))}
                        type='body-3-bold'
                        variant='tertiary'
                      />
                    </div>
                  </div>
                </button>
              )
            );
          })}
          <button
            type='button'
            className='btn-invoice-method-mobile'
            onClick={handleOpenOtherMethods}
          >
            <div className={`btn-invoice-method-border-mobile`}>
              <div className='position-relative'>
                <div className='position-absolute btn-invoice-method-icon-point'>
                  <Icon icon={IconPointFilled} size='medium' color='#30ABA9' />
                </div>
                <Icon icon={IconSettings} size='extraLarge' color='#CBD5E1' />
              </div>
              <div className='btn-invoice-method-text'>
                <Typography
                  text='Otros métodos de pago'
                  type='caption-bold'
                  variant='tertiary'
                />
              </div>
            </div>
          </button>
        </div>
      ) : (
        <div>
          <div className='payment-methods-main'>
            {mainPaymentMethodList.map((method) => {
              return (
                <button
                  key={get(method, 'id')}
                  type='button'
                  className='btn-invoice-method'
                  data-testid={`payment-method-${get(method, 'name')}`}
                  onClick={() => {
                    dispatch(setPaymentMethod(get(method, 'name')));
                    sendPaymentMethodSelectedEvent(paymentMethodSelected);
                    form.change('method', paymentMethodSelected);
                    dispatch(setIsPaymentMethodSelected(true));
                  }}
                >
                  <div
                    className={`btn-invoice-method-border ${paymentMethodSelected === get(method, 'name') ? 'selected' : ''}`}
                  >
                    <div className='btn-invoice-method-icon'>
                      <Icon
                        size='extraLarge'
                        icon={getPaymentMethodIcon(get(method, 'name'))}
                        color='#64748B'
                      />
                    </div>
                    <div className='btn-invoice-method-text'>
                      <Typography
                        text={getPaymentMethodName(get(method, 'name'))}
                        type='body-3-bold'
                        variant='tertiary'
                      />
                    </div>
                  </div>
                </button>
              );
            })}
          </div>
          <Divider text={I18n.get('otherMethods')} />
          {otherPaymentMethodList.length > 2 ? (
            <div className='payment-methods-carousel'>
              <div
                className='payment-methods-carousel-button-prev'
                style={{ visibility: !isBeginning ? 'visible' : 'hidden' }}
              >
                <Avatar
                  icon={IconChevronLeft}
                  type='circle'
                  variant='secondary'
                />
              </div>
              <Swiper
                onSlideChange={(value) => {
                  setIsBeginning(value.isBeginning);
                  setIsEnd(value.isEnd);
                }}
                navigation={{
                  nextEl: '.payment-methods-carousel-button-next',
                  prevEl: '.payment-methods-carousel-button-prev',
                }}
                slidesPerView={4.5}
                spaceBetween={16}
                breakpoints={{
                  200: {
                    slidesPerView: 2,
                  },
                  500: {
                    slidesPerView: 3,
                  },
                  800: {
                    slidesPerView: 4.2,
                  },
                }}
              >
                {otherPaymentMethodList.map((method) => {
                  return (
                    <SwiperSlide key={get(method, 'id')}>
                      <button
                        key={get(method, 'id')}
                        type='button'
                        className='btn-invoice-method'
                        onClick={() => {
                          dispatch(setPaymentMethod(get(method, 'name')));
                          sendPaymentMethodSelectedEvent(paymentMethodSelected);
                          form.change('method', paymentMethodSelected);
                          dispatch(setIsPaymentMethodSelected(true));
                        }}
                      >
                        <div
                          className={`btn-invoice-method-border ${paymentMethodSelected === get(method, 'name') ? 'selected' : ''}`}
                        >
                          <div className='btn-invoice-method-icon'>
                            {get(method, 'logo.url') ? (
                              <img src={get(method, 'logo.url')} />
                            ) : (
                              <Icon
                                size='extraLarge'
                                icon={getPaymentMethodIcon(get(method, 'name'))}
                                color='#64748B'
                              />
                            )}
                          </div>
                          <div className='btn-invoice-method-text'>
                            <Typography
                              text={getPaymentMethodName(get(method, 'name'))}
                              type='body-3-bold'
                              variant='tertiary'
                            />
                          </div>
                        </div>
                      </button>
                    </SwiperSlide>
                  );
                })}
                {wompiState === 'active' && (
                  <>
                    <SwiperSlide key='wompi-bancolombia'>
                      <Tooltip
                        visible={total.isLessThan(WOMPI_MIN_TOTAL)}
                        overlay={replaceAndParse(
                          I18n.get(
                            'bancolombiaMinTotalError',
                            'El monto mínimo es de {} COP para pagos con QR Bancolombia.'
                          ),
                          [WOMPI_MIN_TOTAL.toFormat(decimal, fmt)]
                        )}
                        placement='bottom'
                      >
                        <button
                          type='button'
                          className='btn d-flex flex-column btn-invoice-method-border align-items-center pt-2 gap-3'
                          onClick={() => {
                            sendPaymentMethodSelectedEvent('wompi-bancolombia');
                            form.change('method', 'bancolombiaQR');
                            dispatch(setPaymentMethod('bancolombiaQR'));
                            dispatch(setIsPaymentMethodSelected(true));
                          }}
                          disabled={total.isLessThan(WOMPI_MIN_TOTAL)}
                        >
                          <div className='btn-invoice-method-icon flex-column'>
                            <img
                              style={{ width: '4rem', height: '2.2rem' }}
                              className='mb-1'
                              src={Wompi}
                              alt='wompi'
                            />
                            <img
                              style={{ width: '3rem', height: '2.5rem' }}
                              className='mb-2'
                              src={Bancolombia}
                              alt='bancolombia'
                            />
                          </div>
                          <div className='btn-invoice-method-text'>
                            <Typography
                              text={I18n.get('', 'QR Bancolombia')}
                              type='body-3-bold'
                              variant='tertiary'
                            />
                          </div>
                        </button>
                      </Tooltip>
                    </SwiperSlide>
                    <SwiperSlide key='wompi-nequi'>
                      <Tooltip
                        visible={total.isLessThan(WOMPI_MIN_TOTAL)}
                        overlay={replaceAndParse(
                          I18n.get(
                            'nequiMinTotalError',
                            'El monto mínimo es de {} COP para pagos con Nequi.'
                          ),
                          [WOMPI_MIN_TOTAL.toFormat(decimal, fmt)]
                        )}
                        placement='bottom'
                      >
                        <button
                          type='button'
                          className='btn d-flex flex-column btn-invoice-method-border align-items-center pt-2 gap-3'
                          onClick={() => {
                            sendPaymentMethodSelectedEvent('wompi-nequi');
                            form.change('method', 'nequiPush');
                            dispatch(setPaymentMethod('nequiPush'));
                            dispatch(setIsPaymentMethodSelected(true));
                          }}
                          disabled={total.isLessThan(WOMPI_MIN_TOTAL)}
                        >
                          <div className='btn-invoice-method-icon flex-column'>
                            <img
                              style={{ width: '4rem', height: '2.2rem' }}
                              className='mb-1'
                              src={Wompi}
                              alt='wompi'
                            />
                            <Nequi />
                          </div>
                          <div className='btn-invoice-method-text'>
                            <Typography
                              text={I18n.get('nequi', 'Nequi')}
                              type='body-3-bold'
                              variant='tertiary'
                            />
                          </div>
                        </button>
                      </Tooltip>
                    </SwiperSlide>
                  </>
                )}
                <SwiperSlide key='otherMethods'>
                  <button
                    type='button'
                    className='btn d-flex flex-column btn-invoice-method-border align-items-center pt-4 gap-3'
                    onClick={handleOpenOtherMethods}
                  >
                    <div className='position-relative'>
                      <div className='position-absolute btn-invoice-method-icon-point'>
                        <Icon
                          icon={IconPointFilled}
                          size='medium'
                          color='#30ABA9'
                        />
                      </div>
                      <Icon
                        icon={IconSettings}
                        size='extraLarge'
                        color='#CBD5E1'
                      />
                    </div>
                    <div className='btn-invoice-method-text'>
                      <Typography
                        text='Otros métodos de pago'
                        type='caption-bold'
                        variant='tertiary'
                      />
                    </div>
                  </button>
                </SwiperSlide>
              </Swiper>
              <div
                className='payment-methods-carousel-button-next'
                style={{ visibility: !isEnd ? 'visible' : 'hidden' }}
              >
                <Avatar
                  icon={IconChevronRight}
                  type='circle'
                  variant='secondary'
                />
              </div>
            </div>
          ) : (
            <div
              className={`payment-methods-secondary ${otherPaymentMethodList.length < 2 ? 'incomplete' : ''}`}
            >
              {otherPaymentMethodList.map((method) => {
                return (
                  <button
                    key={get(method, 'id')}
                    type='button'
                    className='btn-invoice-method'
                    data-testid={`payment-method-${get(method, 'name')}`}
                    onClick={() => {
                      dispatch(setPaymentMethod(get(method, 'name')));
                      sendPaymentMethodSelectedEvent(paymentMethodSelected);
                      form.change('method', paymentMethodSelected);
                      dispatch(setIsPaymentMethodSelected(true));
                    }}
                  >
                    <div
                      className={`btn-invoice-method-border ${paymentMethodSelected === get(method, 'name') ? 'selected' : ''}`}
                    >
                      <div className='btn-invoice-method-icon'>
                        <Icon
                          size='extraLarge'
                          icon={getPaymentMethodIcon(get(method, 'name'))}
                          color='#64748B'
                        />
                      </div>
                      <div className='btn-invoice-method-text'>
                        <Typography
                          text={getPaymentMethodName(get(method, 'name'))}
                          type='body-3-bold'
                          variant='tertiary'
                        />
                      </div>
                    </div>
                  </button>
                );
              })}
              <button
                type='button'
                className='btn d-flex flex-column btn-invoice-method-border align-items-center pt-4 gap-3'
                onClick={handleOpenOtherMethods}
              >
                <div className='position-relative'>
                  <div className='position-absolute btn-invoice-method-icon-point'>
                    <Icon
                      icon={IconPointFilled}
                      size='medium'
                      color='#30ABA9'
                    />
                  </div>
                  <Icon icon={IconSettings} size='extraLarge' color='#CBD5E1' />
                </div>
                <div className='btn-invoice-method-text'>
                  <Typography
                    text='Otros métodos de pago'
                    type='caption-bold'
                    variant='tertiary'
                  />
                </div>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NewModalMethods;
