import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get, has, isEmpty } from 'lodash';

import { electronicInvoicing as isCompanyElectronic, settings } from './company'
import { activeBanks } from './banks'
import { activeWarehouses } from './warehouses'
import { activeSellers } from './sellers'
import { normalUsers } from './users'
import { activeCostCenters } from './costCenters'
import { activePriceLists, getMainPriceList } from './priceLists';
import { getInvoiceNumerations, getCashReceiptNumerations, getRefundNumerations, getElectronicNumerations } from './numerations'
import { API } from 'aws-amplify';
import Cookie from 'js-cookie';

const appSelector = state => get(state, 'app', null);
const idUserSelector = state => get(state, 'auth.idGlobal', null);
const idCompanySelector = state => get(state, 'auth.company.id', null);
export const globalStateSelector = state => state;

const removeInitApp = (state) => {
  const { app, ...rest } = state;
  const { initApp, ...appWithoutInitApp } = app;
  return {
    ...rest,
    app: appWithoutInitApp,
  };
};

export const globalState = createDraftSafeSelector(
  [globalStateSelector],
  (globalState) => removeInitApp(globalState)
)

export const APIGraphqlSelector2 = (state) => async (graphqlOperation) => {
  const iduser = get(state, 'auth.idGlobal', null);
  const idcompany = get(state, 'auth.company.id', null);

  return await API.graphql(graphqlOperation, {
    iduser,
    idcompany
  });
}

export const APIGraphqlSelector = createDraftSafeSelector(
  [idUserSelector, idCompanySelector],
  (iduser, idcompany) => async (graphqlOperation) => {
    return API.graphql(graphqlOperation, {
      iduser,
      idcompany,
      blockApiCalls: Cookie.get("pos-block-api-request")
    });
  }
)

export const error = createDraftSafeSelector(
  appSelector,
  app => get(app, 'initApp.error', false)
)

export const loading = createDraftSafeSelector(
  appSelector,
  app => get(app, 'initApp.loading', true) ?? true
)

export const syncingInvoice = createDraftSafeSelector(
  appSelector,
  app => get(app, 'syncingInvoice', false)
)

export const blockApiCalls = createDraftSafeSelector(
  appSelector,
  app => get(app, 'blockApiCalls', false)
)

export const station = createDraftSafeSelector(
  appSelector,
  app => get(app, 'station', null)
)

export const searchProductType = createDraftSafeSelector(
  appSelector,
  app => get(app, 'searchProductType', null)
)

export const stationDebitBank = createDraftSafeSelector(
  [station, activeBanks],
  (station, banks) => {
    if (!!station && !!banks) {
      try {
        let bank = null;
        const { idDebit } = station;

        bank = banks.find(n => +n.id === +idDebit)

        if (!bank && banks.length === 1)
          bank = banks[0]

        return bank
      } catch {
        return null
      }
    }
    return null;
  }
)

export const stationCreditBank = createDraftSafeSelector(
  [station, activeBanks],
  (station, banks) => {
    if (!!station && !!banks) {
      try {
        let bank = null;
        const { idCredit } = station;

        bank = banks.find(n => +n.id === +idCredit)

        if (!bank && banks.length === 1)
          bank = banks[0]

        return bank
      } catch {
        return null
      }
    }
    return null;
  }
)

export const stationTransferBank = createDraftSafeSelector(
  [station, activeBanks],
  (station, banks) => {
    if (!!station && !!banks) {
      try {
        let bank = null;
        const { idTransfer } = station;

        bank = banks.find(n => +n.id === +idTransfer)

        if (!bank && banks.length === 1)
          bank = banks[0]

        return bank
      } catch {
        return null
      }
    }
    return null;
  }
)

export const stationCashBank = createDraftSafeSelector(
  [station, activeBanks],
  (station, banks) => {
    if (!!station && !!banks) {
      try {
        let bank = null;
        const { idCash } = station;

        bank = banks.find(n => +n.id === +idCash)

        if (!bank && banks.length === 1)
          bank = banks[0]

        return bank
      } catch {
        return null
      }
    }
    return null;
  }
)

export const stationBaseBank = createDraftSafeSelector(
  [station, activeBanks],
  (station, banks) => {
    if (!!station && !!banks) {
      try {
        let bank = null;
        const { baseBank } = station;

        bank = banks.find(n => +n.id === +baseBank)

        if (!bank && banks.length === 1)
          bank = banks[0]

        return bank
      } catch {
        return null
      }
    }
    return null;
  }
)

export const stationCloseBank = createDraftSafeSelector(
  [station, activeBanks],
  (station, banks) => {
    if (!!station && !!banks) {
      try {
        let bank = null;
        const { closeBank } = station;

        bank = banks.find(n => +n.id === +closeBank)

        if (!bank && banks.length === 1)
          bank = banks[0]

        return bank
      } catch {
        return null
      }
    }
    return null;
  }
)

export const stationWarehouse = createDraftSafeSelector(
  [station, activeWarehouses],
  (station, warehouses) => {
    if (!!station && !!warehouses) {
      try {
        let warehouse = null;
        const { idWarehouse } = station;

        warehouse = warehouses.find(n => +n.id === +idWarehouse)

        if (!warehouse && warehouses.length === 1)
          warehouse = warehouses[0]

        return warehouse
      } catch {
        return null
      }
    }
    return null;
  }
)

export const stationCostCenter = createDraftSafeSelector(
  [station, activeCostCenters],
  (station, costCenters) => {
    if (!station || !costCenters || !get(station, 'idCostCenter')) return null;

    return costCenters.find(costCenter => ('' + costCenter.id === '' + station.idCostCenter)) || null;
  }
)

export const stationPriceList = createDraftSafeSelector(
  [station, activePriceLists, getMainPriceList],
  (station, priceLists, mainPriceList) => {
    if (!station || !priceLists || !get(station, 'idPriceList')) return mainPriceList;
    const priceList = priceLists.find(priceList => `${priceList.id}` === `${station.idPriceList}`) || mainPriceList;

    return { ...priceList };
  }
)

export const stationSeller = createDraftSafeSelector(
  [station, activeSellers],
  (station, sellers) => {
    if (!station || !sellers || !get(station, 'seller')) return null;

    return sellers.find(seller => ('' + seller.id === '' + station.seller)) || null;
  }
)

export const stationUsers = createDraftSafeSelector(
  [station, normalUsers],
  (station, users) => {
    if (!station || !users || !has(station, 'users')) return [];

    const userIds = station.users.map(user => user.idUser);
    if (isEmpty(users))
      return userIds;

    return users.filter(user => userIds.includes(user.idGlobal));
  }
)

export const stationInvoiceNumeration = createDraftSafeSelector(
  [station, getInvoiceNumerations],
  (station, numerations) => {
    if (!!station && !!numerations) {
      try {
        let numeration = null;
        const { idNumberTemplate } = station;

        numeration = numerations.find(n => +n.id === +idNumberTemplate)

        if (!numeration && numerations.length === 1)
          numeration = numerations[0]

        return numeration
      } catch {
        return null
      }
    }
    return null;
  }
)

export const stationInvoiceNumberNumeration = createDraftSafeSelector(
  [station, getInvoiceNumerations],
  (station, numerations) => {
    if (!!station && !!numerations) {
      try {
        let numeration = null;
        const { idInvoiceNumberTemplate } = station;

        numeration = numerations.find(n => +n.id === +idInvoiceNumberTemplate)

        if (!numeration && numerations.length === 1)
          numeration = numerations[0]

        return numeration
      } catch {
        return null
      }
    }
    return null;
  }
)

export const stationFENumberTemplate = createDraftSafeSelector(
  [station, getElectronicNumerations, settings],
  (station, numerations, settings) => {
    if (!!station && !!numerations) {
      try {
        let numeration = null;
        const { idFENumberTemplate } = station;
        let id = idFENumberTemplate;

        if (id === null) {
          id = get(settings, 'automationElectronicInvoiceIdResolution', null)
        }

        numeration = numerations.find(n => +n.id === +id)
        if (!numeration && numerations.length >= 1)
          numeration = numerations[0]

        return numeration
      } catch {
        return null
      }
    }
    return null;
  }
)

export const stationCashReceiptNumeration = createDraftSafeSelector(
  [station, getCashReceiptNumerations],
  (station, numerations) => {
    if (!!station && !!numerations) {
      try {
        let numeration = null;
        const { idCashReceiptNumberTemplate } = station;

        numeration = numerations.find(n => +n.id === +idCashReceiptNumberTemplate)

        if (!numeration)
          numeration = numerations.find(n => !!n.isDefault)

        if (!numeration && numerations.length === 1)
          numeration = numerations[0]

        return numeration
      } catch {
        return null
      }
    }
    return null;
  }
)

export const stationRefundNumeration = createDraftSafeSelector(
  [station, getRefundNumerations],
  (station, numerations) => {
    if (!!station && !!numerations) {
      try {
        let numeration = null;
        const { idRefundNumberTemplate } = station;

        numeration = numerations.find(n => +n.id === +idRefundNumberTemplate)

        if (!numeration)
          numeration = numerations.find(n => !!n.isDefault)

        if (!numeration && numerations.length === 1)
          numeration = numerations[0]

        return numeration
      } catch {
        return null
      }
    }
    return null;
  }
)

export const offlineData = createDraftSafeSelector(
  [appSelector],
  (app) => !Object.values(app.offlineStatus).every(status => !status)
)

export const electronicInvoicing = (numeration) => createDraftSafeSelector(
  [stationInvoiceNumeration, isCompanyElectronic],
  (stationNumeration, isCompanyElectronic) => {
    const electronicNumeration = !!numeration
      ? get(numeration, 'isElectronic', false)
      : !!stationNumeration
        ? get(stationNumeration, 'isElectronic', false)
        : false;
    return electronicNumeration && isCompanyElectronic
  }
)

export const electronicRefund = (numeration) => createDraftSafeSelector(
  [stationRefundNumeration, isCompanyElectronic],
  (stationNumeration, isCompanyElectronic) => {
    const electronicNumeration = !!numeration
      ? get(numeration, 'isElectronic', false)
      : !!stationNumeration
        ? get(stationNumeration, 'isElectronic', false)
        : false;
    return electronicNumeration && isCompanyElectronic
  }
)

export const showNotificationDownloadAppMenu = createDraftSafeSelector(
  appSelector,
  app => get(app, 'showNotificationDownloadAppMenu', false)
)

export const showNotificationTopDownloadApp = createDraftSafeSelector(
  appSelector,
  app => get(app, 'showNotificationTopDownloadApp', false)
)