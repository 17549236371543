import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Form } from 'react-final-form';
import { get, capitalize, some } from 'lodash';
import {
  currency,
  client as clientSelector,
} from '../../../../selectors/activeInvoice';
import { getMainCurrency } from '../../../../selectors/currencies';
import { closeModal } from '../../../../reducers/modals';
import {
  total,
  numeration as invoiceNumerationSelector,
} from '../../../../selectors/activeInvoice';
import {
  stationDebitBank,
  stationCreditBank,
  stationTransferBank,
  stationInvoiceNumeration,
  stationSeller,
  electronicInvoicing,
  stationCashBank,
} from '../../../../selectors/app';
import {
  country as countrySelector,
  anotation as companyAnotation,
} from '../../../../selectors/company';
import { BasicData, AdvancedData } from '../../../forms/invoice/Invoice';
import Modal from '../../../common/Modal';
import Notification from '../../common/Notification';
import Bottom from '../../common/Bottom';
import Total from '../../invoice/Total';
import Change from '../../invoice/Change';
import Methods from '../../invoice/Methods';
import { replaceAndParse } from '../../../../utils';
import { useDecimalsVersionsGroup } from '../../../../hooks/useDecimalsVersionsGroup';
import { Tooltip } from '@alegradev/smile-ui-react';
import CreditLimitNotification from '../../../forms/invoice/CreditLimitNotification';
import { showCreditLimitField } from '../../../../pages/contacts/utils/limitCredit';
import { InvoiceTextCountryStrategyFactory } from '../strategies/InvoiceTextCountryStrategyFactory';
import { useFormat } from '../../../../hooks/useFormat';
import useTotalToCollect from '../../../../hooks/useTotalToCollect/hook';
import { validate } from '../utils/invoice';
import UseSubmitInvoice from '../hooks/useSubmitInvoice';
import { INVOICE_PAYMENT_METHODS } from '../utils/constants';

const Invoice = () => {
  const [canCredit, setCanCredit] = useState(true);
  const [activeNequiPush, setActiveNequiPush] = useState(true);
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.invoiceRefactored.isOpen', false)
  );
  const totalPrice = useSelector(total);
  const mainCurrency = useSelector(getMainCurrency);
  const selectedCurrency = useSelector(currency);
  const country = useSelector(countrySelector);
  const mainNumeration = useSelector(stationInvoiceNumeration);
  const invoiceNumeration = useSelector(invoiceNumerationSelector);
  const numeration = !!invoiceNumeration ? invoiceNumeration : mainNumeration;
  const isElectronic = useSelector(electronicInvoicing(numeration));
  const defaultAnotation = useSelector(companyAnotation);
  const client = useSelector(clientSelector);
  const synchronizingClient = useSelector((state) =>
    get(state, 'clients.synchronizingClient', false)
  );
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const { totalToCollect, isTotalToCollectLoading, getBalanceByClient } =
    useTotalToCollect({
      client,
    });
  const { fmt, decimal } = useFormat();

  const currencyCode = !!get(selectedCurrency, 'code')
    ? get(selectedCurrency, 'code')
    : !!get(mainCurrency, 'code')
      ? get(mainCurrency, 'code')
      : '';

  const cashBank = useSelector(stationCashBank);
  const debitBank = useSelector(stationDebitBank);
  const creditBank = useSelector(stationCreditBank);
  const transferBank = useSelector(stationTransferBank);
  const seller = useSelector(stationSeller);

  const strategy = InvoiceTextCountryStrategyFactory.createStrategy(country);

  const { submit, loading } = UseSubmitInvoice();

  const onCloseModal = async () => {
    dispatch(closeModal({ modal: 'invoiceRefactored' }));
  };

  useEffect(() => {
    if (showCreditLimitField(country) && isOpen) {
      getBalanceByClient();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => (loading ? null : onCloseModal())}
      hideClose={loading}
      title={replaceAndParse(I18n.get('payAnyDocument', 'pagar {}'), [
        strategy.getModalTitle(numeration),
      ])}
      className='modal__invoice'
    >
      <Form
        onSubmit={submit}
        validate={(values) =>
          validate({
            ...values,
            country,
            decimal,
            isDecimalActive,
            total: totalPrice,
          })
        }
        initialValues={{
          cashBank,
          debitBank,
          creditBank,
          transferBank,
          numeration,
          seller,
          anotation: defaultAnotation,
          paymentMethod: {
            value: 'CASH',
            label: capitalize(I18n.get('paymentForm.Cash', 'Contado')),
          },
          email: get(client, 'email', null),
          phone: get(client, 'mobile', null),
        }}
        keepDirtyOnReinitialize
      >
        {({
          handleSubmit,
          values,
          form,
          error,
          pristine,
          submitting,
          submitError,
          submitFailed,
        }) => {
          const anotationMaxLimit =
            country === 'republicaDominicana' &&
            isElectronic &&
            get(values, 'anotation.length', 0) > 250;
          return (
            <form
              noValidate
              data-testid='form-check-in'
              onSubmit={handleSubmit}
            >
              <div className='d-flex flex-column p-3 modal__invoice-body'>
                <Total values={values} form={form} total={totalPrice} />

                {!!values.method && (
                  <Change values={values} total={totalPrice} />
                )}

                {values.method === INVOICE_PAYMENT_METHODS.NEQUI &&
                  !values.transfer && (
                    <div className='modal__invoice-segmented-control p-2 mb-3'>
                      <div
                        className={`w-100 modal__invoice-segmented-control-${activeNequiPush ? 'active' : 'inactive'}`}
                        onClick={() => setActiveNequiPush(true)}
                      >
                        <p className='h4 inter-regular m-0 p-0'>
                          {I18n.get(
                            'sendRequestToApp',
                            'Enviar solicitud a la app'
                          )}
                        </p>
                      </div>
                      <div
                        className={`w-100 modal__invoice-segmented-control-${!activeNequiPush ? 'active' : 'inactive'}`}
                        onClick={() => setActiveNequiPush(false)}
                      >
                        <p className='h4 inter-regular m-0 p-0'>
                          {I18n.get('scanQRCode', 'Escanear código QR')}
                        </p>
                      </div>
                    </div>
                  )}
                <div
                  className={`flex-column flex-sm-row ${!values.method ? 'd-none' : 'd-flex'}`}
                >
                  <div className='w-100 modal__invoice-basic-data'>
                    <BasicData
                      values={values}
                      form={form}
                      total={totalPrice}
                      fmt={fmt}
                      currencyCode={currencyCode}
                      activeNequiPush={activeNequiPush}
                    />
                  </div>

                  <div className='w-100 modal__invoice-advanced-data'>
                    <AdvancedData form={form} values={values} />
                  </div>
                </div>
                <div
                  className={
                    !values.method || !showCreditLimitField(country)
                      ? 'd-none'
                      : ''
                  }
                >
                  <CreditLimitNotification
                    client={client}
                    values={values}
                    total={totalPrice}
                    totalToCollect={totalToCollect}
                    setCanCredit={setCanCredit}
                  />
                </div>
                <div className={!!values.method ? 'd-none' : ''}>
                  <Methods form={form} total={totalPrice} />
                </div>
              </div>

              {!!error && !!submitFailed && (
                <Notification
                  isOpen={true}
                  text={replaceAndParse(error)}
                  type='error'
                />
              )}

              {!!submitError && !!submitFailed && (
                <Notification
                  isOpen={true}
                  text={replaceAndParse(submitError)}
                  type='error'
                />
              )}

              <Tooltip
                visible={synchronizingClient && isElectronic}
                children={
                  <Bottom
                    hideRequired
                    hideCancel={loading || !!values.transfer}
                    onClose={() =>
                      dispatch(closeModal({ modal: 'invoiceRefactored' }))
                    }
                    submitText={strategy.getSubmitButtonName({
                      values,
                      numeration,
                      isElectronic,
                    })}
                    disabled={
                      some(values?.decimalsError, (value) => value) ||
                      isTotalToCollectLoading ||
                      submitting ||
                      pristine ||
                      (synchronizingClient && isElectronic) ||
                      anotationMaxLimit ||
                      ([
                        INVOICE_PAYMENT_METHODS.BANCOLOMBIA,
                        INVOICE_PAYMENT_METHODS.NEQUI,
                      ].includes(get(values, 'method')) &&
                        !values.transfer) ||
                      !canCredit
                    }
                    submitting={
                      submitting || (synchronizingClient && isElectronic)
                    }
                  />
                }
                overlay={I18n.get(
                  'clientIsSyncing',
                  'El cliente se está sincronizando'
                )}
              />
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};

export default Invoice;
