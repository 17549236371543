import React, { useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flipped, Flipper } from 'react-flip-toolkit';
import anime from 'animejs';
import { I18n } from '@aws-amplify/core';
import { get, capitalize, throttle } from 'lodash';

import { openModal } from '../../../reducers/modals';
import { searchMore, fetchItems } from '../../../reducers/items';
import { userSelector } from '../../../selectors/auth';
import { loading, error, isFiltered, allItems, searching } from '../../../selectors/items';
import {
  allItemCategories,
  selectedCategories,
} from '../../../selectors/itemCategories';
import {
  country as countrySelector,
  decimalPrecision,
  origin as originSelector,
} from '../../../selectors/company';
import { getMainCurrency } from '../../../selectors/currencies';
import { replaceAndParse, isClothingStore } from '../../../utils';
import Item1PNG from '../../images/item1.png';
import Item2PNG from '../../images/item2.png';
import Item3PNG from '../../images/item3.png';
import ItemCategories from './ItemCategories';
import MembershipNotification from './MembershipNotification';
import EconomicActivityNotification from './EconomicActivityNotification';
import NotificationBox from '../../notifications/NotificationBox';
import Search from './Search';
import Item from './Item';
import ARGUniqueTributeCourseNotification from '../../notifications/ARGUniqueTributeCourseNotification';
import LimitedQueryPlanNotification from '../../notifications/LimitedQueryPlanNotification';
import LimitedPlanNotification from '../../notifications/LimitedPlanNotification';
import UserWithPendingInvoicesNotification from '../../notifications/UserWithPendingInvoicesNotification';
import ElectronicNumerationsNotification from '../../notifications/ElectronicNumerationsNotification';
// import ActivateFETutorialNotification from '../../notifications/ActivateFETutorialNotification';
// import NumerationBlockingNotification from '../../notifications/NumerationBlockingNotification';
// import ConsultingPlanNotification from './ConsultingPlanNotification';

import { Icon } from '@alegradev/smile-ui-react';
import ElectronicPOSDocumentTopNotification from '../../notifications/ElectronicPOSDocumentTopNotification';
import ElectronicPOSDocumentSecondTopNotification from '../../notifications/ElectronicPOSDocumentSecondTopNotification';
import DownloadAppNotification from '../../notifications/DownloadAppNotification';
import { IconLoader2 } from '@tabler/icons-react';
import TicketBAITopNotification from '../../notifications/TicketBAITopNotification';

const exampleItems = [
  {
    id: '-1',
    name: capitalize(I18n.get('item', 'producto')),
    description: capitalize(I18n.get('description', 'Descripción')),
    reference: null,
    price: [
      {
        idPriceList: '1',
        name: 'General',
        price: '20000.0000',
        type: 'amount',
      },
    ],
    tax: [],
    category: {
      id: '5062',
      name: 'Ingresos de actividades ordinarias',
    },
    inventory: {
      unit: 'service',
    },
    status: 'active',
    type: 'simple',
    customFields: [],
    isFavorite: false,
    images: [{ id: '-1', name: 'item1.png', url: Item1PNG }],
  },
  {
    id: '-2',
    name: capitalize(I18n.get('item', 'producto')),
    description: capitalize(I18n.get('description', 'Descripción')),
    reference: null,
    price: [
      {
        idPriceList: '1',
        name: 'General',
        price: '20000.0000',
        type: 'amount',
      },
    ],
    tax: [],
    category: {
      id: '5062',
      name: 'Ingresos de actividades ordinarias',
    },
    inventory: {
      unit: 'service',
    },
    status: 'active',
    type: 'simple',
    customFields: [],
    isFavorite: false,
    images: [{ id: '-2', name: 'item2.png', url: Item2PNG }],
  },
  {
    id: '-3',
    name: capitalize(I18n.get('item', 'producto')),
    description: capitalize(I18n.get('description', 'Descripción')),
    reference: null,
    price: [
      {
        idPriceList: '1',
        name: 'General',
        price: '20000.0000',
        type: 'amount',
      },
    ],
    tax: [],
    category: {
      id: '5062',
      name: 'Ingresos de actividades ordinarias',
    },
    inventory: {
      unit: 'service',
    },
    status: 'active',
    type: 'simple',
    customFields: [],
    isFavorite: false,
    images: [{ id: '-3', name: 'item3.png', url: Item3PNG }],
  },
];

const Items = () => {
  const dispatch = useDispatch();
  const isOnboardingOpen = useSelector((state) =>
    get(state, 'tours.onboarding.isOpen')
  );
  let items = useSelector(allItems);
  const itemCategories = useSelector(allItemCategories);
  const itemsSelectedCategories = useSelector(selectedCategories);
  const hasCategories = !!itemCategories && !!itemCategories.length;
  const itemsListRef = useRef(null);
  const itemsLoading = useSelector(loading);
  const itemsSearching = useSelector(searching);
  const itemsError = useSelector(error);
  const isItemFiltered = useSelector(isFiltered);
  const decimal = useSelector(decimalPrecision);
  const companyCurrency = useSelector(getMainCurrency);
  const itemsPercent = useSelector((state) =>
    get(state, 'items.loadingPercent', false)
  );
  const country = useSelector(countrySelector);
  const user = useSelector(userSelector);
  const origin = useSelector(originSelector);
  const itemState = useSelector((state) => get(state, 'items', {}));
  const { hasMoreItems, isFetchingMore, loadingPercent, filters } = itemState;

  items = !!isOnboardingOpen ? exampleItems : items;

  const elementIn = (el) => {
    anime({
      targets: el,
      opacity: 1,
      scale: [0.9, 1],
      easing: 'easeInBack',
      duration: 250,
    });
  };

  const elementOut = (el, _index, onComplete) => {
    anime({
      targets: el,
      opacity: 0,
      scale: 0.8,
      easing: 'easeOutBack',
      duration: 200,
      complete: onComplete,
    });
  };

  const selectedCategoriesNames = useMemo(() => {
    const onlySelectedCategories = (cat) =>
      itemsSelectedCategories.some((id) => id === cat.id);
    return itemCategories.filter(onlySelectedCategories).map((e) => e.name);
  }, [itemsSelectedCategories, itemCategories]);

  const handlerScroll = throttle(() => {
    const elem = itemsListRef.current;
    const dynamicThreshold = elem.scrollHeight * 0.05;

    const isLoadingComplete = loadingPercent === 100;
    const shouldFetchMore =
      (hasMoreItems && !isFetchingMore) ||
      (!filters.text && (isLoadingComplete || !hasMoreItems));

    if (
      elem.clientHeight + elem.scrollTop + dynamicThreshold >=
      elem.scrollHeight
    ) {
      if (isLoadingComplete || shouldFetchMore) {
        dispatch(searchMore());
      }
    }
  }, 500);

  return (
    <div className='items d-none d-sm-block'>
      <ItemCategories />

      <div
        className={`items-section d-flex flex-column ${hasCategories ? 'category' : ''
          } main-new-color`}
      >
        {/* <NumerationBlockingNotification /> */}

        <ARGUniqueTributeCourseNotification />

        {/* <ColombiaNewBillNotification /> */}

        <UserWithPendingInvoicesNotification />

        <ElectronicNumerationsNotification />

        {/* <HappyWeekMay23Notification /> */}

        {/* <ActivateFEGlobalNotification /> */}

        {/* <ActivateFETutorialNotification /> */}

        <LimitedQueryPlanNotification />

        <LimitedPlanNotification />

        {/* <NoIvaDaysNotification /> */}

        <NotificationBox userId={user.idGlobal} />

        <MembershipNotification />

        {/* <ConsultingPlanNotification /> */}

        <EconomicActivityNotification />

        <ElectronicPOSDocumentTopNotification />

        <ElectronicPOSDocumentSecondTopNotification />

        <TicketBAITopNotification />

        <DownloadAppNotification />

        <Search />

        {itemsLoading && isItemFiltered && itemsPercent !== 100 && selectedCategoriesNames.length > 0 && (
          <p className='bg-warning p-2 h4 text-capitalize-first text-left'>
            {I18n.get(
              'itemsAreLoadingInBackground',
              'aún hay productos cargando para su uso offline. Esto puede demorar unos minutos.'
            )}
          </p>
        )}

        {items.length === 0 && itemsLoading && !isItemFiltered && (
          <div className='w-100 h-100 d-flex align-items-center justify-content-center flex-column px-2'>
            <p className='text-center text-capitalize-first h4'>
              {I18n.get(
                'itemsInitialLoad',
                'cargando productos, esta operación puede demorar unos minutos'
              )}
            </p>
            <Icon icon={IconLoader2} animated extraClass=' icon-primary icon x3' />
          </div>
        )}

        {!!itemsSearching && (
          <div className='w-100 h-100 d-flex align-items-center justify-content-center flex-column px-2'>
            <p className='text-center text-capitalize-first h4'>
              {I18n.get(
                'itemsSearching',
                'buscando productos'
              )}
            </p>
            <Icon icon={IconLoader2} animated extraClass=' icon-primary icon x2' />
          </div>
        )}

        {items.length === 0 && !!selectedCategoriesNames.length && (
          <div className='w-100 h-100 d-flex align-items-center justify-content-center flex-column px-2'>
            <p className='text-capitalize-first h4 mb-3 text-center'>
              {selectedCategoriesNames.length === 1
                ? replaceAndParse(
                  I18n.get(
                    'itemCategoryIsEmpty',
                    'La categoría {} está vacía en la bodega de esta terminal. 🌪️'
                  ),
                  [selectedCategoriesNames[0]]
                )
                : I18n.get(
                  'itemCategoriesAreEmpty',
                  'Las categorías que seleccionaste están vacías en la bodega de esta terminal. 🔍'
                )}
            </p>
          </div>
        )}

        {items.length === 0 &&
          isItemFiltered &&
          !selectedCategoriesNames.length && (
            <div className='w-100 h-100 d-flex align-items-center justify-content-center flex-column px-2'>
              <p className='text-capitalize-first h4 mb-3 text-center'>
                {I18n.get(
                  'noItemsResultSearch',
                  'Puedes probar con otro código o nombre e intentarlo de nuevo'
                )}
              </p>
            </div>
          )}

        {items.length === 0 && !!itemsError && (
          <div className='w-100 h-100 d-flex align-items-center justify-content-center flex-column px-2'>
            <p className='text-capitalize-first mb-3 text-center'>
              {I18n.get(
                'itemsLoadError',
                'Sucedió un error cargando los productos'
              )}
            </p>
            <button
              type='button'
              className='btn btn-submit'
              onClick={() => dispatch(fetchItems())}
            >
              {I18n.get('retry', 'reintentar')}
            </button>
          </div>
        )}

        {items.length === 0 &&
          !isItemFiltered &&
          !itemsLoading &&
          !itemsError && (
            <div className='w-100 h-100 d-flex align-items-center justify-content-center flex-column px-2'>
              <p className='text-capitalize-first h3 mb-3 text-center'>
                {isClothingStore(country, origin)
                  ? I18n.get(
                    'notItemsYetClothingStores',
                    'Comienza creando tu primer producto aquí'
                  )
                  : I18n.get(
                    'notItemsYet',
                    'Comienza creando tu primer producto aquí'
                  )}{' '}
              </p>
              <button
                type='button'
                onClick={() => dispatch(openModal({ modal: 'item' }))}
                className={`btn ${isClothingStore(country, origin)
                  ? 'btn-new-product'
                  : 'btn-submit'
                  }`}
              >
                {isClothingStore(country, origin)
                  ? I18n.get('newItemClothingStores', 'nuevo producto')
                  : I18n.get('newItem', 'nuevo producto')}
              </button>
            </div>
          )}

        {items.length !== 0 && (
          <Flipper flipKey={items.toString()} className='overflow-hidden'>
            <div
              className='items-grid d-flex flex-wrap justify-content-around align-content-start'
              ref={itemsListRef}
              onScroll={() => handlerScroll()}
            >
              {items.map((item) => (
                <Flipped
                  key={`${item.id} ${decimal} ${!!get(companyCurrency, 'symbol')
                    ? get(companyCurrency, 'symbol')
                    : ''
                    }`}
                  flipId={item.id}
                  onAppear={elementIn}
                  onExit={elementOut}
                  translate
                  scale
                  children={(flippedProps) => (
                    <Item item={item} flippedProps={flippedProps} />
                  )}
                />
              ))}
            </div>
          </Flipper>
        )}
      </div>
    </div>
  );
};

export default Items;
