import { COUNTRIES } from '../../../utils/enums/countries';

const COUNTRIES_AVAILABLE = [
  COUNTRIES.INTERNATIONAL,
  COUNTRIES.USA,
  COUNTRIES.PERU,
  COUNTRIES.PANAMA,
  COUNTRIES.REPUBLICA_DOMINICANA,
  COUNTRIES.SPAIN,
];
export const showNewInvoiceModal = (country) => {
  return COUNTRIES_AVAILABLE.includes(country);
};
