import { I18n } from '@aws-amplify/core';
import { get, capitalize, isArray, find, isEmpty, replace, trim } from 'lodash';
import { BigNumber } from 'bignumber.js';
import dayjs from 'dayjs';

import { toast } from '../../../utils';

import { referenceTypesTemporal } from './RapidData';
import getNoTax from '../../countriesData/general/noTax';
import { COUNTRIES } from '../../../utils/enums/countries';
import { validateNumberString } from '../../../utils/decimals/validateNumberString';

export const oldValidate = (values, { country, customFields }) => {
  let errors = {};

  const name = get(values, 'name', '');
  const price = get(values, 'price', '');
  const salePrice = get(values, 'salePrice', '');
  const isInventariable = get(values, 'inventariable', false);

  if (!name)
    errors.name = capitalize(I18n.get('enterAName', 'Ingresa un nombre'));
  if (!validateNumberString(price))
    errors.price = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );
  if (!validateNumberString(salePrice))
    errors.salePrice = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );

  if (isInventariable) {
    if (!get(values, 'unit.key', null))
      errors.unit = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
    if (!get(values, 'unitCost', null))
      errors.unitCost = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
  }

  if (isInventariable && !get(values, 'warehouses.length')) {
    if (!validateNumberString(get(values, 'initialQuantity', null)))
      errors.initialQuantity = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
  }

  if (country === 'costaRica') {
    if (
      !!get(values, 'tariffHeading', null) &&
      get(values, 'tariffHeading').length < 12
    )
      errors.tariffHeading =
        capitalize(
          I18n.get(
            'theMinimumSizeForThisFieldIs',
            'El tamaño minimo para este campo es de'
          )
        ) + ' 12.';
    if (
      !!get(values, 'tariffHeading', null) &&
      get(values, 'tariffHeading').length > 12
    )
      errors.tariffHeading =
        capitalize(
          I18n.get(
            'theMaximumSizeForThisFieldIs',
            'El tamaño maximo para este campo es de'
          )
        ) + ' 12.';
  }

  if (country === 'mexico') {
    if (!get(values, 'productKey', null))
      errors.productKey = capitalize(
        I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
      );
  }

  customFields.map((field) => {
    const value = get(values, `customFields.cf${get(field, 'id')}`);
    const isBoolean = get(field, 'type') === 'boolean';
    const isRequired = !!get(field, 'settings.isRequired', null);
    if (!!isRequired && !isBoolean && !value) {
      errors = {
        ...errors,
        customFields: {
          ...errors.customFields,
          [`cf${get(field, 'id')}`]: capitalize(
            I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
          ),
        },
      };
    }
    return null;
  });

  return errors;
};

// Helper function to generate a standard error message
const requiredFieldError = () =>
  capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio'));

// Helper function to generate a character length error message
const charLengthError = (fieldName, isMax, length) =>
  capitalize(
    I18n.get(
      isMax ? 'maxCharactersExceeded' : 'minCharactersRequired',
      `${fieldName} debe tener ${isMax ? 'máximo' : 'mínimo'} ${length} caracteres`
    )
  );

export const validate = (values, { country, customFields, isElectronic }) => {
  let errors = {};

  // Standard required field validation
  const requiredFields = ['name', 'price', 'salePrice', 'type'];
  requiredFields.forEach((field) => {
    if (!validateNumberString(get(values, field, ''))) {
      errors[field] = requiredFieldError();
    }
  });

  // Specific field validations based on 'type'
  if (values.type === 'kit' && !get(values, 'kitWarehouse.id')) {
    errors.kitWarehouse = requiredFieldError();
  }
  if (values.type === 'product') {
    if (!get(values, 'unit.key')) errors.unit = requiredFieldError();
    if (!validateNumberString(get(values, 'unitCost'))) errors.unitCost = requiredFieldError();
  }

  // Variant validation
  if (values.hasVariants) {
    values.itemVariants?.map((variant, index) => {
      if (variant.inventory?.singleWarehouseInitialQuantity === null || variant.inventory?.singleWarehouseInitialQuantity === undefined) {
        errors.itemVariants = errors.itemVariants || [];
        errors.itemVariants[index] = errors.itemVariants[index] || {};
        errors.itemVariants[index].inventory =
          errors.itemVariants[index].inventory || {};

        errors.itemVariants[index].inventory.singleWarehouseInitialQuantity =
          requiredFieldError();
      }
    });
  }

  // Check if errors.itemVariants are all '' then remove the key
  if (errors.itemVariants && errors.itemVariants.every((error) => error === '')) {
    delete errors.itemVariants;
  }

  // Country-specific validations
  const countryValidations = {
    costaRica: () => {
      if (values.reference?.length > 20) errors.reference = charLengthError('reference', true, 20);
      if (!values.productKey?.key && isElectronic) errors.productKey = requiredFieldError();
    },
    colombia: () => {
      const tariffHeading = values.tariffHeading?.length;
      if (tariffHeading < 10 || tariffHeading > 10) errors.tariffHeading = charLengthError('tariffHeading', tariffHeading > 10, 10);
    },
    mexico: () => {
      if (!values.productKey?.key) errors.productKey = requiredFieldError();
    },
    republicaDominicana: () => {
      if (values.reference?.length > 35) errors.reference = charLengthError('reference', true, 35);
      if (values.name?.length > 80) errors.name = charLengthError('name', true, 80);
    },
    peru: () => {
      if (values.reference?.length > 100) errors.reference = charLengthError('reference', true, 100);
    },
  };

  // Execute the relevant country-specific validation
  if (countryValidations[country]) countryValidations[country]();

  // Custom fields validation
  customFields.forEach((field) => {
    const fieldValue = get(values, `customFields.cf${field.id}`);
    if (field.type !== 'boolean' && !fieldValue) {
      errors[`customFields.cf${field.id}`] = requiredFieldError();
    }
  });

  return errors;
};

const showErrorToast = (errorKey, defaultMessage, messageKey, defaultMessageDetails) => {
  toast.error({
    title: I18n.get(errorKey, defaultMessage),
    subtitle: I18n.get(messageKey, defaultMessageDetails),
  });
};

const validateProductWithVariants = (values) => {
  const hasVariants = get(values, 'hasVariants', false);
  if (hasVariants && !get(values, 'itemVariants.length')) {
    showErrorToast(
      'createItemSubmitError1',
      'Configura la sección de variantes.',
      'createItemSubmitError1Message',
      'Desde la sección variantes debes seleccionar por lo menos una opción e indicar la cantidad disponible en bodega de las que elijas.'
    );
    return true;
  }
  return false;
};

const validateKit = (values) => {
  let kitItems = get(values, 'items', []).filter((item) => get(item, 'item.id'));
  if (kitItems.length === 0) {
    showErrorToast(
      'createItemSubmitError2',
      'Agrega los productos que integran tu combo.',
      'createItemSubmitError2Message',
      'Podrás asignar la cantidad de cada uno para empezar a venderlo y tener el control de las unidades disponibles e ingresos.'
    );
    return true;
  }
  return false;
};

export const validateOnSubmit = (values) => {
  const type = get(values, 'type', '');

  switch (type) {
    case 'product':
      return validateProductWithVariants(values);
    case 'kit':
      return validateKit(values);
    default:
      return false;
  }
};

const dynamicFieldTransform = (value, type) => {
  switch (type) {
    case 'int':
    case 'decimal':
      return !isNaN(+value) ? +value : null;
    case 'boolean':
      return Boolean(value);
    case 'date':
      return dayjs(value).isValid() ? dayjs(value).format('YYYY-MM-DD') : null;
    case 'optionsList':
      return get(value, 'value', null);
    case 'text':
      return isEmpty(value.trim()) ? null : value;
    default:
      return value;
  }
};

const transformItemVariantsWarehouse = (values, negativeSale) => {
  if (!Array.isArray(values) || values.length === 0) {
    return null;
  }

  return values.map((variant) => {
    const warehouses = get(variant, 'inventory.warehouses', []);
    const singleWarehouseId = get(variant, 'inventory.singleWarehouse.id');
    const singleWarehouseInitialQuantity = get(variant, 'inventory.singleWarehouseInitialQuantity');

    if (warehouses.length <= 1) {
      const [firstWarehouse = {}] = warehouses;
      return {
        ...variant,
        inventory: {
          ...variant.inventory,
          warehouses: [{
            id: singleWarehouseId,
            initialQuantity: singleWarehouseInitialQuantity,
            maxQuantity: firstWarehouse.maxQuantity,
            minQuantity: firstWarehouse.minQuantity,
            negativeSale,
          }],
        },
      };
    }

    return variant;
  });
};

// Simplify tax condition logic
const getTaxCondition = (country, taxes) => {
  if (country !== 'mexico') return null;
  const customTax = get(taxes, '[0].customTax', false);
  return { value: customTax ? get(taxes, '[0].id') : '02' };
};

// Simplify custom fields transformation
const transformCustomFields = (values, allCustomFields) => {
  return allCustomFields.filter((customField) =>
    JSON.stringify(get(values, `customFields.cf${get(customField, 'id')}`))
  ).map((field) => ({
    id: get(field, 'id'),
    name: get(field, 'name'),
    type: get(field, 'type'),
    value: dynamicFieldTransform(
      get(values, `customFields.cf${get(field, 'id')}`),
      get(field, 'type')
    ),
  })).filter((customFields) => customFields.value !== null);
};

// Simplify inventory logic
const getInventory = (values, type, distributed, itemType, country) => {
  const isProductOrSimple = type === 'product' || type === 'simple';
  if (!isProductOrSimple) return null;

  const baseInventory = {
    unit: get(values, 'unit.key'),
    unitCost: get(values, 'unitCost'),
    negativeSale: get(values, 'negativeSale', false),
  };

  if (distributed || itemType !== 'variantParent') {
    return {
      ...baseInventory,
      warehouses: values.warehouses.map((warehouse) => ({
        id: get(warehouse, 'warehouse.id'),
        initialQuantity: get(warehouse, 'initialQuantity'),
        minQuantity: get(warehouse, 'minQuantity'),
        maxQuantity: get(warehouse, 'maxQuantity'),
        negativeSale: get(values, 'negativeSale'),
      })),
    };
  }
  return {
    ...baseInventory,
    initialQuantity: get(values, 'initialQuantity'),
  };
};

export const transform = (values, { country, decimal, allCustomFields, categories }) => {
  let taxes = get(values, 'tax', []);
  taxes = isArray(taxes) ? taxes : [taxes];
  taxes = taxes.filter(tax => get(tax, 'id') !== -1);

  const countryHasTaxCondition = country === 'mexico';
  const taxCondition = getTaxCondition(country, taxes);

  const price = get(values, 'salePrice', '0');
  const usedCustomFields = transformCustomFields(values, allCustomFields);

  const type = get(values, 'type', country === 'republicaDominicana' ? 'product' : 'simple');
  const distributed = !!get(values, 'inventariable', false) && !!get(values, 'warehouses.length');

  const itemType = type !== 'kit'
    ? get(values, 'hasVariants', false) ? 'variantParent' : type
    : 'kit';

  const subitems = !!get(values, 'items.length')
    ? values.items.filter(item => get(item, 'item.id'))
    : [];
    
  return {
    name: get(values, 'name', ''),
    image: get(values, 'imageSrc', null),
    type: itemType,
    tax: countryHasTaxCondition
      ? (taxCondition && get(taxCondition, 'value') === '02' ? (isArray(taxes) && taxes.length > 0 ? taxes.map(tax => get(tax, 'id')) : null) : [])
      : (isArray(taxes) && taxes.length > 0 ? taxes.map(tax => get(tax, 'id')) : null),
    reference: country === 'costaRica' && !!get(values, 'reference', null)
      ? {
        reference: trim(get(values, 'reference')),
        type: get(values, 'referenceType.code', null),
      }
      : trim(get(values, 'reference', '')),
    productKey: get(values, 'productKey.key', null),
    itemCategory: get(values, 'category.id', null),
    tariffHeading: get(values, 'tariffHeading', null),
    brand: get(values, 'brand', null),
    model: get(values, 'model', null),
    customFields: usedCustomFields,
    description: get(values, 'description', ''),
    inventory: getInventory(values, type, distributed, itemType, country),
    price: [{ price }]
      .concat(
        !!get(values, 'priceLists.length', false)
          ? values.priceLists
            .filter(
              (priceList) =>
                get(priceList, 'priceList.idPriceList', null) &&
                get(priceList, 'priceList.idPriceList', null) !== 1
            )
            .map((priceList) => ({
              idPriceList: get(priceList, 'priceList.idPriceList'),
              price: get(priceList, 'price'),
            }))
          : []
      )
      .concat(
        !!get(values, 'priceLists.length', false)
          ? values.priceLists
            .filter((priceList) => !!get(priceList, 'priceList.id', null))
            .map((priceList) => {
              const idPriceList = get(priceList, 'priceList.id')
              const priceListPercentage = new BigNumber(get(priceList, 'priceList.percentage'))
              const priceListType = get(priceList, 'priceList.type')

              return ({
                idPriceList,
                price:
                  priceListType === 'percentage'
                    ? new BigNumber(100)
                      .minus(
                        priceListPercentage
                      )
                      .dividedBy(100)
                      .multipliedBy(new BigNumber(price))
                      .decimalPlaces(decimal)
                      .toNumber()
                    : get(priceList, 'price', '0'),
              })
            }
            )
          : []
      ),
    category: get(values, 'accountingAccount.id', null),
    kitWarehouse: itemType === 'kit' ? get(values, 'kitWarehouse.id', null) : null,
    accounting: {
      inventory: get(categories, 'inventoryCategory', null),
      inventoryPurchase: get(categories, 'inventoryPurchaseCategory', null),
    },
    subitems: itemType === 'kit' && !!subitems.length ? subitems : null,
    variantAttributes: itemType === 'variantParent' ? get(values, 'variantAttributes', null) : null,
    itemVariants: itemType === 'variantParent' ? transformItemVariantsWarehouse(get(values, 'itemVariants', null), get(values, 'negativeSale')) : null,
    taxCondition,
    predial: country === 'mexico' ? get(values, 'predial', null) : null,
    idPharmaceuticalForm: get(values, 'idPharmaceuticalForm.id', null),
    drugRegistration: get(values, 'drugRegistration', null),
    unit: get(values, 'unit.key'),
  };
};

// export const transform = (
//   values,
//   { country, decimal, allCustomFields, categories }
// ) => {
//   let taxes = !!get(values, 'tax', null) ? get(values, 'tax') : [];
//   taxes = !isArray(taxes) ? [taxes] : taxes;

//   taxes = taxes.filter((tax) => get(tax, 'id') !== -1);

//   let countryHasTaxCondition = country === 'mexico';
//   let taxCondition = countryHasTaxCondition
//     ? get(taxes, '[0].customTax', false)
//       ? { value: get(taxes, '[0].id') }
//       : { value: '02' }
//     : null;

//   const price = get(values, 'salePrice', '0');

//   const usedCustomFields = allCustomFields.filter((customField) =>
//     JSON.stringify(get(values, `customFields.cf${get(customField, 'id')}`))
//   );

//   const type = get(
//     values,
//     'type',
//     country === 'republicaDominicana' ? 'product' : 'simple'
//   );

//   const distributed =
//     !!get(values, 'inventariable', false) && !!get(values, 'warehouses.length');

//   const itemType =
//     type !== 'kit'
//       ? get(values, 'hasVariants', false)
//         ? 'variantParent'
//         : type
//       : 'kit';

//   const subitems = !!get(values, 'items.length')
//     ? values.items.filter((item) => get(item, 'item.id'))
//     : [];

//   return {
//     name: get(values, 'name', ''),
//     image: get(values, 'imageSrc', null),
//     type: itemType,
//     tax: countryHasTaxCondition
//       ? taxCondition && get(taxCondition, 'value') === '02'
//         ? !!isArray(taxes) && taxes.length > 0
//           ? taxes.map((tax) => tax.id)
//           : null
//         : []
//       : !!isArray(taxes) && taxes.length > 0
//         ? taxes.map((tax) => tax.id)
//         : null,
//     reference:
//       country === 'costaRica'
//         ? !!get(values, 'reference', null)
//           ? {
//             reference: trim(get(values, 'reference', null)),
//             type: get(values, 'referenceType.code', null),
//           }
//           : null
//         : trim(get(values, 'reference', null)),
//     productKey: get(values, 'productKey.key', null),
//     itemCategory: get(values, 'category.id', null),
//     tariffHeading: get(values, 'tariffHeading', null),
//     brand: get(values, 'brand', null),
//     model: get(values, 'model', null),
//     customFields: usedCustomFields.map((field) => ({
//       id: get(field, 'id'),
//       name: get(field, 'name'),
//       type: get(field, 'type'),
//       value: dynamicFieldTransform(
//         get(values, `customFields.cf${get(field, 'id')}`),
//         get(field, 'type')
//       ),
//     })),
//     description: get(values, 'description', ''),
//     inventory:
//       type === 'product' || type === 'simple'
//         ? distributed || itemType !== 'variantParent'
//           ? {
//             unit: get(values, 'unit.key'),
//             unitCost: get(values, 'unitCost'),
//             negativeSale: get(values, 'negativeSale', false),
//             warehouses: values.warehouses.map((warehouse) => ({
//               id: get(warehouse, 'warehouse.id'),
//               initialQuantity: get(warehouse, 'initialQuantity'),
//               minQuantity: get(warehouse, 'minQuantity'),
//               maxQuantity: get(warehouse, 'maxQuantity'),
//               negativeSale: get(values, 'negativeSale'),
//             })),
//           }
//           : {
//             unit: get(values, 'unit.key'),
//             unitCost: get(values, 'unitCost'),
//             negativeSale: get(values, 'negativeSale', false),
//             initialQuantity: get(values, 'initialQuantity'),
//           }
//         : country === 'mexico' ||
//           country === 'peru' ||
//           country === 'costaRica' ||
//           country === 'argentina' ||
//           country === 'colombia'
//           ? { unit: get(values, 'unit.key', 'service') }
//           : null,
//     price: [{ idPriceList: '1', price }]
//       .concat(
//         !!get(values, 'priceLists.length', false)
//           ? values.priceLists
//             .filter(
//               (priceList) =>
//                 get(priceList, 'priceList.idPriceList', null) &&
//                 get(priceList, 'priceList.idPriceList', null) !== 1
//             )
//             .map((priceList) => ({
//               idPriceList: get(priceList, 'priceList.idPriceList'),
//               price: get(priceList, 'price'),
//             }))
//           : []
//       )
//       .concat(
//         !!get(values, 'priceLists.length', false)
//           ? values.priceLists
//             .filter((priceList) => !!get(priceList, 'priceList.id', null))
//             .map((priceList) => ({
//               idPriceList: get(priceList, 'priceList.id'),
//               price:
//                 get(priceList, 'priceList.type') === 'percentage'
//                   ? new BigNumber(100)
//                     .minus(
//                       new BigNumber(get(priceList, 'priceList.percentage'))
//                     )
//                     .dividedBy(100)
//                     .multipliedBy(new BigNumber(price))
//                     .decimalPlaces(decimal)
//                     .toNumber()
//                   : get(priceList, 'price', '0'),
//             }))
//           : []
//       ),
//     category: get(values, 'accountingAccount.id', null),
//     kitWarehouse:
//       itemType === 'kit' ? get(values, 'kitWarehouse.id', null) : null,
//     accounting: {
//       inventory: get(categories, 'inventoryCategory', null),
//       inventoryPurchase: get(categories, 'inventoryPurchaseCategory', null),
//     },
//     subitems: itemType === 'kit' && !!subitems.length ? subitems : null,
//     variantAttributes:
//       itemType === 'variantParent'
//         ? get(values, 'variantAttributes', null)
//         : null,
//     itemVariants:
//       itemType === 'variantParent'
//         ? // ? get(values, 'itemVariants', null)
//         transformItemVariantsWarehouse(
//           get(values, 'itemVariants', null),
//           get(values, 'negativeSale')
//         )
//         : null,
//     taxCondition,
//     predial: country === 'mexico' ? get(values, 'predial', null) : null,
//     unit: get(values, 'unit.key'),
//   };
// };

// export const transform = (values, { country, decimal, allCustomFields, categories }) => {
//   if (coutriesUpdate.includes(country)) {
//     return newTransformer(values, { country, decimal, allCustomFields, categories })
//   } else {
//     return oldTransformer(values, { country, decimal, allCustomFields })
//   }
// }

export const transformFromAPI = (
  item,
  { unitOptions, decimal, warehouses, language },
  country
) => {
  const taxesArray = isArray(get(item, 'tax', [])) ? get(item, 'tax', []) : [get(item, 'tax', [])];
  const type = get(item, 'type', '');
  const firstPrice = get(item, 'price[0].price');
  const referenceType = !!get(item, 'reference.type', null)
    ? referenceTypesTemporal.find(
      (referenceType) =>
        get(referenceType, 'key') === get(item, 'reference.type')
    )
    : null;
  let iepsValue = new BigNumber(0);
  let taxesValue = new BigNumber(0);
  let firstTax = parseInt(get(item, 'tax[0].percentage', 0)) / 100;
  firstTax = firstTax === 0 ? 1 : firstTax + 1;

  const haveIEPStax = taxesArray.some(t => get(t, "type", '') === "IEPS");

  if (haveIEPStax && country === COUNTRIES.MEXICO) {
    iepsValue = taxesArray.reduce((acc, t) => {
      if (get(t, "type", '') === "IEPS") {
        return acc.plus(new BigNumber(t.percentage).dividedBy(100).multipliedBy(new BigNumber(firstPrice)))
      }
      return acc
    }, new BigNumber(0))
  }

  taxesArray.forEach((t) => {
    const isTaxIVA = get(t, 'type') === 'IVA';
    const taxPercent = new BigNumber(t.percentage);
    let tax = new BigNumber(0);

    if (haveIEPStax && isTaxIVA && country === COUNTRIES.MEXICO) {
      tax = taxPercent.dividedBy(100).multipliedBy(new BigNumber(firstPrice).plus(iepsValue))
    } else {
      tax = taxPercent.dividedBy(100).multipliedBy(new BigNumber(firstPrice))
    }

    taxesValue = taxesValue.plus(tax)
  });

  let tax = isEmpty(get(item, 'tax'))
    ? [getNoTax(language.substring(0, 2))]
    : get(item, 'tax');

  const getVariantFavoriteImage = (variant) => {
    const allImages = get(variant, 'images', []);

    if (allImages.length === 0) return '';

    const favoriteImage = allImages.find((image) => get(image, 'favorite', false));

    return get(favoriteImage, 'url', '');
  };

  const price = new BigNumber(firstPrice).plus(taxesValue).toFixed(decimal);

  return {
    ...item,
    accountingAccount: get(item, 'category', {}),
    customFields: get(item, 'customFields', []).reduce((prev, current) => {
      if (get(current, 'type') === 'optionsList')
        return {
          ...prev,
          ['cf' + current.id]: { value: current.value, label: current.value },
        };
      else
        return {
          ...prev,
          ['cf' + current.id]: current.value,
        };
    }, {}),
    priceLists: get(item, 'price', []).map((pL) => ({
      price: new BigNumber(pL.price).toFixed(decimal),
      priceList: pL,
    })),
    type,
    warehouses: get(item, 'inventory.warehouses', []).map((w) => {
      let warehouse = find(warehouses, { id: get(w, 'id') });
      return {
        initialQuantity: parseInt(get(w, 'initialQuantity')),
        minQuantity: get(w, 'minQuantity'),
        maxQuantity: get(w, 'maxQuantity'),
        warehouse,
      };
    }),
    hasVariants: type === 'variantParent',
    name: get(item, 'name', ''),
    unit: find(unitOptions, { key: get(item, 'inventory.unit') }),
    unitCost:
      type === 'variantParent'
        ? new BigNumber(
          get(item, 'itemVariants[0].inventory.unitCost')
        ).toFixed(decimal)
        : type === 'product' || type === 'simple'
          ? new BigNumber(get(item, 'inventory.unitCost')).toFixed(decimal)
          : 0,
    salePrice: new BigNumber(firstPrice).toFixed(decimal),
    price,
    tax: tax,
    description: get(item, 'description', ''),
    negativeSale: !!get(item, 'inventory.negativeSale'),
    reference: !!get(item, 'reference.reference', null)
      ? get(item, 'reference.reference', '')
      : get(item, 'reference', ''),
    referenceType: referenceType,
    productKey: !!get(item, 'productKey')
      ? {
        key: get(item, 'productKey'),
        name: get(item, 'productKey'),
      }
      : null,
    variantAttributes: get(item, 'variantAttributes', []),
    itemVariants: get(item, 'itemVariants', []).map((variant) => ({
      ...variant,
      inventory: {
        ...get(variant, 'inventory'),
        singleWarehouse: get(variant, 'inventory.warehouses[0]'),
        singleWarehouseInitialQuantity: get(
          variant,
          'inventory.warehouses[0].initialQuantity'
        ),
      },
      status: {
        value: get(variant, 'status'),
        label: `${get(variant, 'status')}Products`,
      },
      image: getVariantFavoriteImage(variant),
    })),
    items: get(item, 'subitems', []).map((i) => ({
      item: i.item,
      averageCost: new BigNumber(
        get(i, 'item.inventory.averageCost', 0) * Number(i.quantity)
      ),
      quantity: Number(i.quantity),
    })),
    imageSrc:
      !!get(item, 'images.length') &&
      get(item, `images.${get(item, 'images.length', 1) - 1}.url`, ''),
    category: get(item, 'itemCategory'),
    idPharmaceuticalForm: get(item, 'idPharmaceuticalForm.id', null),
    drugRegistration: get(item, 'drugRegistration', null),
    isEditing: true,
  };
};

export const transformVariants = (values, { customFields }) => {
  return {
    // image: get(values, 'image', null),
    customFields: customFields.map((field) => ({
      id: get(field, 'id'),
      value: dynamicFieldTransform(
        get(
          values,
          `customFields.cf${get(field, 'id')}`,
          get(values, `customFields[0].value`)
        ),
        get(field, 'type')
      ),
    })),
    inventory: {
      unit: get(values, 'unit.key'),
      unitCost: get(values, 'unitCost'),
      singleWarehouseInitialQuantity: get(
        values,
        'warehouses.0.initialQuantity'
      ),
      singleWarehouse: get(values, 'warehouses.0.warehouse'),
      warehouses: values.warehouses.map((warehouse) => ({
        id: get(warehouse, 'warehouse.id'),
        initialQuantity: get(warehouse, 'initialQuantity'),
        minQuantity: get(warehouse, 'minQuantity'),
        maxQuantity: get(warehouse, 'maxQuantity'),
        negativeSale: get(warehouse, 'negativeSale'),
      })),
    },
  };
};

export const initialValuesFromModal = ({
  customFields,
  type,
  initValues,
  mainCategory,
  mainWarehouse,
}) => {
  let initialValues = {
    customFields: {},
  };

  customFields.map((field) => {
    if (get(field, 'type') === 'optionsList') {
      initialValues.customFields[`cf${get(field, 'id')}`] = {
        value: get(field, 'defaultValue', null),
        label: get(field, 'defaultValue', null),
      };
    } else if (get(field, 'type') === 'boolean')
      initialValues.customFields[`cf${get(field, 'id')}`] =
        get(field, 'defaultValue', null) === '1';
    else
      initialValues.customFields[`cf${get(field, 'id')}`] = get(
        field,
        'defaultValue',
        null
      );

    return null;
  });

  if (initialValues) {
    initialValues = {
      ...initialValues,
      ...initValues,
      warehouses: [
        {
          warehouse: initValues.warehouse,
          initialQuantity: initValues.initialQuantity,
          unitCost: initValues.unitCost,
        },
      ],
      items: [{}, {}, {}],
      accountingAccount: mainCategory,
      kitWarehouse: mainWarehouse,
    };
  }

  initialValues['type'] = !!type ? type : 'product';

  initialValues = {
    ...initialValues,
    type: !!type ? type : 'product',
    hasVariants: false,
    priceLists: [{}],
  };

  return initialValues;
};
